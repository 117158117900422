import React, { useContext, useEffect, useState, useRef } from 'react'
import { Spinner } from 'react-bootstrap'
import Url from "../lib/Url"
import { RootContext } from "./RootContext"

export const ResourceContext = React.createContext()

const resolveInitialSort = (defaultSort) => {
  if (Url.getParam('sort'))
    return JSON.parse(Url.getParam('sort'))
  else if (defaultSort)
    return defaultSort
  else
    return {}
}

export default ({ children, baseQuery, defaultSort, id, reloadInterval, mandatoryFilters }) => {
  const { showMessage, testSession } = useContext(RootContext)
  const [ resources, setResources ] = useState(null)
  const [ filter, setFilter ] = useState(Url.getParam('filter') ? JSON.parse(Url.getParam('filter')) : {})
  const [ page, setPage ] = useState(Url.getParam('page') ? parseInt(Url.getParam('page')) : 1)
  const [ sort, setSort ] = useState(resolveInitialSort(defaultSort))
  const [ loading, setLoading ] = useState(false)
  const reloadIntervalHandleRef = useRef(null)
  const filterTimeoutRef = useRef(null)
  const loadCounterRef = useRef(0)

  // Omit conditions that have keys beginning w underscores
  const filterConditions = () => (
    Object.fromEntries(
      Object.entries(filter).filter(
        ([key, _val]) => key[0] !== '_'
      )
    )
  )

  //NOTE! Do not use order in baseQuery, use defaultSort instead (if using sortable column heads)
  const loadResources = () => {
    if (!baseQuery)
      return
    if (mandatoryFilters && mandatoryFilters.some(f => filterConditions()[f] === undefined)){
      setResources(null)
      return
    }
    setResources(null)
    setLoading(true)
    const count = ++loadCounterRef.current
    baseQuery.where(filterConditions()).page(page).order(sort).all().then(collection => {
      // Ignore result if this was not the latest load
      if (count === loadCounterRef.current) {
        setLoading(false)
        setResources(collection)
      }
    }).catch(err => {
      console.error(err)
      setLoading(false)
      testSession()
    })
  }

  useEffect(() => {
    if (reloadInterval && !reloadIntervalHandleRef.current)
      reloadIntervalHandleRef.current = window.setInterval(loadResources, reloadInterval)
    return () => {
      if (reloadIntervalHandleRef.current)
        window.clearInterval(reloadIntervalHandleRef.current)
    }
  }, [])

  useEffect(() => {
    window.clearInterval(filterTimeoutRef.current)
    filterTimeoutRef.current = window.setTimeout(() => {
      loadResources()
    }, 250)
  }, [filter, page, sort, id])

  const reloadResources = (msg = null) => {
    loadResources()
    if (msg) {
      showMessage(msg)
    }
  }

  const defaultContext = {
    resources,
    setResources,
    page,
    setPage,
    filter,
    setFilter,
    sort,
    setSort,
    reloadResources,
    loading
  }

  return (
    <ResourceContext.Provider value={defaultContext}>
      {loading &&
      <div className="resources-loading-spinner">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>}
      {children}
    </ResourceContext.Provider>
  )
}