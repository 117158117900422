import React, {useContext, useEffect, useState} from "react"
import {Spinner} from 'react-bootstrap'
import Breadcrumbs from "../lib/Breadcrumbs"
import Organization from "../resources/Organization"
import {Link, useParams, useRouteMatch, Switch} from 'react-router-dom'
import AuthenticatedRoute from "../components/AuthenticatedRoute"
import UserGroupIndex from "./UserGroupIndex"
import InstrumentIndex from "./InstrumentIndex"
import OrganizationDeviceIndex from "./OrganizationDeviceIndex"
import InstrumentStatusReportIndex from "./InstrumentStatusReportIndex"
import TagIndex from "./TagIndex"
import CommandIndex from "./CommandIndex"
import SubMenu from "../components/SubMenu"
import AutomationIndex from "./AutomationIndex"
import {RootContext} from "../contexts/RootContext"
import OrganizationDeviceActivityReport from "./OrganizationDeviceActivityReport"

export default () => {
  const { canIndex } = useContext(RootContext)
  const [ organization, setOrganization ] = useState(null)
  const { organizationId } = useParams()
  const { path, url } = useRouteMatch()
  const [ loading, setLoading ] = useState(false)

  useEffect(
    () => {
      if (organizationId){
        setLoading(true)
        Organization.find(organizationId).then(resource => {
          setOrganization(resource)
          setLoading(false)
        }).catch(err => {
          console.error(err)
          setLoading(false)
        })
      }
    },
    [organizationId]
  )

  return (
    <div>
      {loading &&
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>}
      {organization && <React.Fragment>
      <Breadcrumbs>
        <Link to="/organizations">
          Organizations
        </Link>
        {organization.fullName}
      </Breadcrumbs>
      <SubMenu baseUrl={url} items={[
        {url: `/instruments`, label: 'Instruments'},
        {url: `/user_groups`, label: 'User groups'},
        canIndex('organizationDevices') ? {url: `/organization_devices`, label: 'Devices'} : null,
        {url: `/tags`, label: 'Tags'},
        {url: `/commands`, label: 'Commands'},
        canIndex('automations') ? {url: `/automations`, label: 'Automations'} : null,
        canIndex('instrumentStatusReports') ? {url: `/status_reports`, label: 'Status reports'} : null,
        organization && !organization.isChild ? {url: '/device_activity_report', label: 'Device report'} : null
      ]}/>
      <Switch>
        <AuthenticatedRoute path={`${path}/user_groups`}>
          <UserGroupIndex organization={organization}/>
        </AuthenticatedRoute>
        <AuthenticatedRoute path={`${path}/organization_devices`}>
          <OrganizationDeviceIndex organization={organization}/>
        </AuthenticatedRoute>
        <AuthenticatedRoute path={`${path}/instruments`}>
          <InstrumentIndex organization={organization}/>
        </AuthenticatedRoute>
        <AuthenticatedRoute path={`${path}/tags`}>
          <TagIndex organization={organization}/>
        </AuthenticatedRoute>
        <AuthenticatedRoute path={`${path}/commands`}>
          <CommandIndex organization={organization}/>
        </AuthenticatedRoute>
        <AuthenticatedRoute path={`${path}/device_activity_report`}>
          <OrganizationDeviceActivityReport organization={organization}/>
        </AuthenticatedRoute>
        <AuthenticatedRoute path={`${path}/automations`}>
          <AutomationIndex organization={organization}/>
        </AuthenticatedRoute>
        <AuthenticatedRoute path={`${path}/status_reports`}>
          <InstrumentStatusReportIndex organization={organization}/>
        </AuthenticatedRoute>
      </Switch></React.Fragment>}
    </div>
  )
}