import React, {useContext, useEffect, useState} from 'react'
import { Form } from 'react-bootstrap'
import { ResourceContext } from '../contexts/ResourceContext'
import FormInput from '../lib/FormInput'
import ResourceModal from './ResourceModal'
import styles from '../styles/CommandModal.module.css'
import FormSelect from "../lib/FormSelect"
import { InstrumentStatusReport } from "../Resources"

export default ({resource, show, onClose}) => {
  const [ formState, setFormState ] = useState({})
  const [ tags, setTags ] = useState([])
  const { reloadResources } = useContext(ResourceContext)
  const [ errors, setErrors ] = useState({})

  useEffect(
    () => {
      if (resource){
        setFormState({
          organization: resource.organization(),
          tag: resource.tag(),
          name: resource.name,
          interval: resource.interval,
          reportType: resource.reportType,
          receiverEmails: resource.receiverEmails,
        })
        void loadTags()
      }
      setErrors({})
    },
    [resource]
  )

  const loadTags = async () => {
    const tags = await resource.organization().tags().perPage(200).all()
    setTags(tags.toArray())
  }

  const handleInputChange = (ev) => {
    const name = ev.target.name
    let value = ev.target.value
    if (ev.target.type === 'checkbox') {
      value = ev.target.checked
    }
    if (name === 'tag') {
      value = tags.find(t => t.id === value)
    }
    let state = {...formState, [name]: value}
    setFormState(state)
  }

  const save = () => {
    const state = Object.assign({}, formState)
    resource.assignAttributes(state)
    const msg = resource.persisted() ? 'Report updated' : 'Report created'
    return resource.save().then(() => {
      reloadResources(msg)
      onClose()
    }).catch(err => {
      let errors = {}
      resource.errors().toArray().forEach(error => errors[error.field] = error.detail)
      setErrors(errors)
    })
  }

  return (
    <ResourceModal
      show={show}
      onClose={onClose}
      onSubmit={save}
      resource={resource}
      label="status report"
      className={styles.modalDialog}
    >
      <Form>
        <FormInput
          readOnly
          onChange={(ev) => {}}
          horizontal
          name="organization"
          value={formState.organization && formState.organization.name}/>
        <FormSelect
          horizontal
          name="reportType"
          label="Type"
          options={[['','- choose -'], ...Object.keys(InstrumentStatusReport.reportTypes).map(k => [k, InstrumentStatusReport.reportTypes[k]])]}
          value={formState.reportType}
          error={errors.reportType}
          onChange={handleInputChange}/>
        <FormInput
          horizontal
          name="name"
          value={formState.name}
          error={errors.name}
          onChange={handleInputChange}/>
        <FormSelect
          horizontal
          name="tag"
          label="Tag (optional)"
          options={[['','- choose -'], ...tags.map(t => [t.id, t.name])]}
          value={formState.tag ? formState.tag.id : ''}
          error={errors.tag}
          onChange={handleInputChange}/>
        <FormSelect
          horizontal
          name="interval"
          label="Delivery interval"
          options={[['','- choose -'], ...Object.keys(InstrumentStatusReport.intervals).map(k => [k, InstrumentStatusReport.intervals[k]])]}
          value={formState.interval}
          error={errors.interval}
          onChange={handleInputChange}/>
        <FormInput
          horizontal
          placeholder='example1@mail.com,example2@mail.com'
          name="receiverEmails"
          value={formState.receiverEmails}
          error={errors.receiverEmails}
          onChange={handleInputChange}/>
      </Form>
    </ResourceModal>
  )
}
